button, h1, h2 {
  font-family: 'Space Mono', monospace!important;
}

.divider {
  display: flex;
  align-items: center;
}

.divider:after {
  content: "";
  flex: 1;
  height: 1px;
  background-image: linear-gradient(to right, #6BE044, #050823);
  margin-left: 10px;
}

.divider-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-center:after {
  content: "";
  flex: 1;
  height: 1px;
  max-width: 200px;
  background-image: linear-gradient(to right, #6BE044, #050823);
  margin-left: 10px;
}

.divider-center:before {
  content: "";
  flex: 1;
  height: 1px;
  max-width: 200px;
  background-image: linear-gradient(to left, #6BE044, #050823);
  margin-right: 10px;
}

/* MOBILE Menu Sabers */

.menu-wrap .hamburger > div:before,
.menu-wrap .hamburger > div:after {
  content: '';
  position: absolute;
  z-index: 10;
  top: -10px;
  width: 100%;
  height: 2px;
  border-right: 22px solid #A0AEC0;
  border-left: 7px solid #2d3748;
  border-radius: 30px;
  background: #fff;
  margin-left: -17px;
}

.saber {
  border-left: 22px solid #A0AEC0;
  border-right: 7px solid #2d3748;
  background: #fff;
}

.menu-wrap .hamburger > div:after {
  top: 10px;
}

.menu-wrap .hamburger > div {
  transition: all 0.4s ease;
}

.menu-wrap .toggler:checked + .hamburger > div {
  transform: rotate(135deg);
  border-left: 22px solid #6BE044;
  box-shadow: 0 0 5px #7EC855;
}

.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
  top: 0;
  transform: rotate(90deg);
  border-right: 22px solid #3273c5;
  box-shadow: 0 0 5px #3784d6;
}

/* LIST Markers */

li {
  display: flex;
  align-items: center;
}

.skills-list:before {
  content: '';
  min-height: 0.8em;
  min-width: 0.8em;
  background-image: url(./components/icons/source.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right:0.5em;
  margin-top: 1px;
}


.data-class-in {
  display: none!important;
}

.data-class-out {
  display: none!important;
}